<script>
  import { onMount } from 'svelte';
  import { GTM_ID } from './config';
  import { isGTMSetupReady } from './stores/core-store';

  onMount(() => {
    if (GTM_ID) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
        isGTMSetupReady.set(true);
      })(window, document, 'script', 'dataLayer', GTM_ID);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
              <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
                      height="0"
                      width="0"
                      style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    } else {
      console.error('GTM_ID is not defined. Ensure your configuration is correct.');
    }
  });
</script>
