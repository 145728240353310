<script>
  /**
   * errorMessage is a global store, so when navigating between pages the error state persists
   * unless it is manually cleared on each page.
   *
   * This clears the error state on a route change such that each page does not need to clear any
   * previous error messages.
   *
   * This also clears the breadcrumb paths in the same manner.
   */

  import { errorMessage, breadcrumbPaths, urlHistory, isGTMSetupReady } from '../stores/core-store';
  import { setupGoogleAnalytics, sendGoogleAnalyticsPageView } from '../util/google-analytics';
  import { GTM_ID } from '../config';
  import { getQueryParameter } from '../util/util';
  import { fetchSession, fetchSessionPlans } from '../util/api/sessions';

  setupGoogleAnalytics(GTM_ID);

  const sendPageViewEvent = async (path) => {
    errorMessage.set('');
    breadcrumbPaths.set([]);

    let tabValue = getQueryParameter('tab');
    tabValue = tabValue === 'qr' || tabValue === 'code' ? tabValue : undefined;

    let sessionAndPlanId = undefined;
    const sessionAndPlanIdRouteHit = [...sessionIdRoutes, ...sessionPlanIdRoutes].find((sessionIdAndPlanRoute) =>
      path.startsWith(`${sessionIdAndPlanRoute}`),
    );
    if (sessionAndPlanIdRouteHit) {
      sessionAndPlanId = path.replace(`${sessionAndPlanIdRouteHit}/`, '');
    }

    let session = undefined;
    try {
      const sessionIdRouteHit = sessionIdRoutes.find((sessionIdRoute) => path.startsWith(`${sessionIdRoute}`));
      if (sessionIdRouteHit) {
        const sessionId = path.replace(`${sessionIdRouteHit}/`, '');
        session = await fetchSession(sessionId);
      }
    } catch (err) {
      console.error(err);
    }

    await sendGoogleAnalyticsPageView(sessionAndPlanId, tabValue, session);
  };

  const sessionIdRoutes = ['/sessions/view', '/sessions/lobby', '/sessions/join'];
  const sessionPlanIdRoutes = ['/sessions/plans/view'];

  urlHistory.subscribe(async (history) => {
    window.scrollTo(0, 0);

    if (history.length >= 1 && history[history.length - 1] !== history[history.length - 2]) {
      const path = history[history.length - 1];
      // Before GTM setup
      if (!$isGTMSetupReady) {
        setTimeout(async () => {
          await sendPageViewEvent(path);
        }, 200);
      } else {
        // After GTM setup
        await sendPageViewEvent(path);
      }
    }
  });
</script>

<style>
</style>
